<template>
  <b-row class="settings">
    <b-col>
      <PageHeader :title="$t('general.settingsPages.email.title')" />

      <b-row>
        <div class="settings__form">
          <div class="settings__input">
            <CInput
              type="email"
              name="email"
              theme="settings"
              class="settings__field"
              v-model="email"
              :label="$t('general.settingsPages.email.placeholderEmail')"
              :rules="'required|email'"
            >
              <template #appendAfter>
                <transition name="fade" mode="out-in">
                  <CIcon
                    name="check"
                    class="settings__icon settings__icon--check"
                    v-if="user.is_email_verified"
                  />
                  <CIcon
                    name="false"
                    class="settings__icon settings__icon--false"
                    v-else
                  />
                </transition>
              </template>

              <template #appendAddition="{ hasErrors }">
                <span class="settings__counter" v-if="!hasErrors">
                  <i18n
                    v-if="user.is_email_verified"
                    tag="span"
                    class="settings__font settings__font--addition"
                    path="general.settingsPages.email.additionText"
                  >
                    <template v-slot:email>
                      {{ email }}
                    </template>
                  </i18n>

                  <i18n
                    v-else
                    tag="span"
                    class="settings__font settings__font--addition"
                    path="general.settingsPages.email.additionTextNotVerified"
                  >
                    <template v-slot:email>
                      {{ email }}
                    </template>
                  </i18n>
                </span>
              </template>
            </CInput>
          </div>

          <div class="settings__input">
            <CInput
              type="password"
              name="password"
              theme="settings"
              class="settings__field"
              v-model="password"
              :label="$t('general.settingsPages.email.placeholderPassword')"
              :rules="'required|min:8'"
            />
          </div>
        </div>

        <div class="settings__button-wrapper">
          <button
            type="button"
            @click.prevent="saveSettings"
            class="base-button settings__button"
          >
            <span class="base-button__font">
              {{ $t("general.privacyPage.button.update") }}
            </span>
          </button>

          <button
            v-if="!user.is_email_verified"
            type="button"
            @click.prevent="emailVerifyRequest"
            class="base-button settings__button"
          >
            <span class="base-button__font">
              {{ $t("general.privacyPage.button.verifyEmail") }}
            </span>
          </button>
        </div>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import PageHeader from "@/layout/PageHeader.vue";
import CInput from "@/features/ui/СInput.vue";
import CIcon from "@/features/ui/CIcon.vue";
import { mapGetters } from "vuex";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
export default {
  components: {
    CIcon,
    CInput,
    PageHeader,
  },
  mixins: [waitRequest, validationError],
  computed: {
    ...mapGetters({ user: "user" }),
  },
  data: function () {
    return {
      email: "",
      password: "",
    };
  },
  mounted() {
    this.loadUser();
  },

  methods: {
    loadUser() {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.$get(
          "/auth/me",
          (data) => {
            this.email = data.email;
          },
          (errors) => {
            console.log(errors);
          },
          this.checkErrors
        );
      });
    },
    saveSettings() {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.$post(
          "/profile/email",
          {
            email: this.email,
            password: this.password,
          },
          () => {
            this.$bvToast.toast(this.$t("general.settings-saved"), {
              autoHideDelay: 2000,
              title: this.$t("general.success"),
              solid: true,
              toaster: "b-toaster-bottom-left flow-toast",
            });
          },
          (errors) => {
            console.log("errors", errors);
          },
          this.checkErrors
        );
      }).catch(this.checkErrors);
    },

    emailVerifyRequest() {
      if (this.requestInProgress) return;
      const fields = {
      };

      const url = new URL(`/code`, process.env.VUE_APP_API_URL);
      url.searchParams.set("type", "email_verification");
      const requestUrl = url.pathname + url.search;

      return this.waitRequest(() => {
        return this.$post(
          requestUrl,
          fields,
          () => {
            this.$bvToast.toast(this.$t("general.emailVerify.toast.check"), {
              autoHideDelay: 2000,
              title: this.$t("general.emailVerify.toast.text"),
              solid: true,
              toaster: "b-toaster-bottom-left flow-toast",
            });
          },
          (errors) => {
            this.$bvToast.toast(this.$t("general.emailVerify.toast.titleError"), {
              autoHideDelay: 2000,
              title: errors.code?.[0],
              solid: true,
              toaster: "b-toaster-bottom-left flow-toast",
            });
          },
          this.checkErrors
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  &__form {
    margin-top: em(32);
    width: 100%;
    margin-right: em(16);
    margin-left: em(16);
  }

  &__button {
    margin-top: em(17);
    margin-right: em(15);
    margin-left: auto;
  }

  &__button-wrapper {
    display: flex;
    width: 100%;
    margin-top: em(19);
    border-top: 1px solid $app-gray-2;
  }

  &__input {
    margin-top: em(16);
    position: relative;
  }

  &__counter {
    position: absolute;
    top: 100%;
    right: em(0);
  }

  &__field {
  }

  &__font {
    &--addition {
      font-family: $font-default;
      font-size: em(12);
      color: $app-font-color-placeholder;
    }
  }

  &__icon {
    color: $app-gray-18;

    &--check {
      width: em(14);
      height: em(10);
    }

    &--false {
      width: em(15);
      height: em(15);
    }
  }
}

.base-button {
  $parent: &;
  width: auto;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 16) em(16, 16);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}
</style>
